import { Component } from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import { Route } from 'react-router';
import { routerMiddleware } from 'connected-react-router';

// import view component.
import Input from './containers/input.jsx';
import Confirm from './presentationals/confirm.jsx';

// import reducer.
import createRootReducer from './reducer.es6';

// import action
import { initialize } from './action.es6';

// import css
import * as style from './style.module.sass';

/**
 * Create a history
 * @const {object}
 */
const history = createBrowserHistory();

/**
 * Register Middleware
 * @const {object}
 */
const middleware = routerMiddleware(history);

/**
 * Create Store
 * @const {object}
 */
const store = configureStore({
  reducer: createRootReducer(history),
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), middleware],
});

/**
 *  EnvCheck
 *  @version 2016/05/02
 *  @author ryo.aso
 */
class EnvCheck extends Component {
  /**
   *  初期描画直後に一度だけ実行
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  UNSAFE_componentWillMount() {
    store.dispatch(initialize(this.props));
  }

  /**
   *  表示処理
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  render() {
    return (
      <div className={style.pc}>
        <Provider {...{ store }}>
          <ConnectedRouter {...{ history }}>
            <div className={style.env}>
              <Route path='/env_check' component={Input} />
              <Route path='/env_check/confirm' component={Confirm} />
            </div>
          </ConnectedRouter>
        </Provider>
      </div>
    );
  }
}

export default EnvCheck;
