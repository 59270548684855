// import modules
import { Component } from 'react';
import * as style from '../style.module.sass';
import { CACHE_RESOURCE, USE_TAG } from '../properties.es6';

/**
 *  ルート
 *  @version 2017/01/05
 *  @author ryo-as
 */
class Item extends Component {
  /**
   *  コンストラクタ
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  constructor() {
    super();

    // 初期値
    this.state = { active: false };
  }

  /**
   *  表示処理
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  render() {
    const { active } = this.state;
    const { title, id, children } = this.props;

    return (
      <li className={style.trouble__item}>
        <h3
          className={style.trouble__heading}
          data-active={active}
          {...{ id }}
          onClick={() => this.setState({ active: !active })}
        >
          {title}
        </h3>
        <div className={style.trouble__text} data-active={active}>
          {children}
        </div>
      </li>
    );
  }
}

/**
 *  ルート
 *  @version 2017/01/05
 *  @author ryo-as
 */
export default () => (
  <section className={style.section}>
    <h2 className={style.section__heading}>トラブルシューティング</h2>
    <ul className={style.trouble}>
      <Item title='指定のブラウザを利用' id='browser'>
        <p>
          CodeCampではChrome上で動作するようにシステムが設計されています。
          <br />
          Chrome以外のブラウザでは不具合が生じる可能性があるためChromeをインストールしてご利用ください。
        </p>
        <a
          href='https://www.google.com/intl/ja/chrome/'
          target='_blank'
          className={style.trouble__link}
          rel='noreferrer'
        >
          Chrome公式サイト
          <svg dangerouslySetInnerHTML={{ __html: USE_TAG }} className='u-linkBlank' />
        </a>
      </Item>
      <Item title='Cookieを有効にする' id='cookie'>
        <p>
          レッスンを受講するためにはCookieを有効にする必要があります。
          <br />
          下記を参考にCookieを有効にしてください。
        </p>
        <ol>
          <li>
            <h4>設定画面を開く</h4>
            <img src={`${CACHE_RESOURCE}img_cookie_01.jpg`} />
          </li>
          <li>
            <h4>コンテンツの設定画面を開く</h4>
            <img src={`${CACHE_RESOURCE}img_cookie_02.jpg`} />
          </li>
          <li>
            <h4>Cookieの設定画面を開く</h4>
            <img src={`${CACHE_RESOURCE}img_cookie_03.jpg`} />
          </li>
          <li>
            <h4>Cookieを許可する</h4>
            <img src={`${CACHE_RESOURCE}img_cookie_04.jpg`} />
          </li>
        </ol>
      </Item>
      <Item title='カメラが起動しない' id='camera'>
        <p>下記を参考にカメラを有効にしてください</p>
        <ol>
          <li>
            <h4>カメラを有効にする</h4>
            <img src={`${CACHE_RESOURCE}img_camera_01.jpg`} />
          </li>
        </ol>
        <p>
          カメラを有効にしているにもかかわらず、映像が表示されない場合はカメラデバイスを選択しなおしてからテストを行ってください。
        </p>
        <ol>
          <li>
            <h4>カメラデバイスを選択する</h4>
            <img src={`${CACHE_RESOURCE}img_camera_02.jpg`} />
          </li>
        </ol>
      </Item>
      <Item title='音量メーターが反応しない' id='sound1'>
        <p>下記を参考にマイクを有効にしてください</p>
        <ol>
          <li>
            <h4>マイクを有効にする</h4>
            <img src={`${CACHE_RESOURCE}img_mic_01.jpg`} />
          </li>
        </ol>
        <p>
          マイクを有効にしているにもかかわらず、音量メータが反応しない場合はマイクデバイスを選択しなおしてからテストを行ってください。
        </p>
        <ol>
          <li>
            <h4>マイクデバイスを選択する</h4>
            <img src={`${CACHE_RESOURCE}img_mic_02.jpg`} />
          </li>
        </ol>
      </Item>
      <Item title='再生時に音が出ない' id='sound2'>
        <p>
          録音時に音量メータが反応しているにも関わらず、再生時に音が出ない場合はスピーカーがミュートになっている可能性があります。
          <br />
          スピーカーのボリュームをご確認ください。
        </p>
      </Item>
      <Item title='解決しない' id='etc'>
        <p>解決しない場合はお手数ですが、メーカーへとお問い合わせください。</p>
      </Item>
    </ul>
  </section>
);
