// import modules
// import styles
import * as stylePC from './style.module.sass';
import * as styleSP from './style.sp.module.sass';

/**
 *  ナビゲーション
 *  @version 2018/09/31
 */
export default ({ phase, device }) => {
  // SP判定
  const isSP = device === 'sp';
  const style = isSP ? styleSP : stylePC;

  return (
    <ul className={style.steps}>
      <li className={`${style.steps__item} ${phase === 'complete' && style.active}`}>
        <span className={style.steps__caption}>{isSP ? '完了' : 'お申込み完了'}</span>
      </li>
      <li className={`${style.steps__item} ${phase === 'confirm' && style.active}`}>
        <span className={style.steps__caption}>{isSP ? '確認' : '内容確認'}</span>
      </li>
      <li className={`${style.steps__item} ${phase === 'address' && style.active}`}>
        <span className={style.steps__caption}>{isSP ? '入力' : '本人情報'}</span>
      </li>
      <li className={`${style.steps__item} ${phase === 'payments' && style.active}`}>
        <span className={style.steps__caption}>{isSP ? '入力' : 'お支払い方法'}</span>
      </li>
      <li className={`${style.steps__item} ${phase.match(/select|charge|extension/) && style.active}`}>
        <span className={style.steps__caption}>{isSP ? '選択' : '商品選択'}</span>
      </li>
    </ul>
  );
};
