import { Component } from 'react';
import range from 'number-array-generator'; // @see https://www.npmjs.com/package/number-array-generator
import { Field } from 'redux-form';
import * as style from '../style.module.sass';

const VALID = {
  INITIALIZE: 0,
  DEVISES: 10,
  STARTUP: 20,
  RECORD: 30,
  PLAY: 40,
};

const RECORDER_OPTIONS = {
  audioBitsPerSecond: 64000,
  videoBitsPerSecond: 512000,
  mimeType: 'video/webm;codecs=vp9',
};

const Gauge = ({ volume }) => (
  <div className={style.gauge}>
    <div className={style.gauge__inner}>
      {range(1, volume / 5).map((num, key) => (
        <span key={key} className={style.gauge__item}>
          *
        </span>
      ))}
    </div>
  </div>
);

/**
 *  ブラウザ
 *  @version 2017/01/05
 *  @author ryo-as
 */
export default class Recorder extends Component {
  /**
   *  コンストラクタ
   *  @version 2017/01/05
   *  @author ryo-as
   */
  constructor(props) {
    super(props);

    // init state
    this.state = {
      valid: VALID.INITIALIZE,
      message: null,
      devices: null,
      volume: 100,
    };

    this.recorder = null;
    this.recordedChunks = [];
  }

  /**
   *  初期描画直後に一度だけ実行
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  UNSAFE_componentWillMount() {
    try {
      // デバイス一覧の取得
      window.navigator.mediaDevices
        .enumerateDevices()
        .then((enumerateDevices) => {
          const devices = {};

          enumerateDevices.forEach((device) => {
            devices[device.kind] = devices[device.kind] || [];
            devices[device.kind].push(device);
          });

          this.setState({ valid: VALID.DEVISES, devices });
        })
        .catch(() =>
          // エラーメッセージ
          this.setState({ message: 'カメラを使用できません' }),
        );
    } catch (error) {
      this.setState({ message: 'カメラを使用できません' });
    }
  }

  /**
   *  デバイス初期化
   *  @version 2017/01/05
   *  @author ryo-as
   */
  initDevice(cameraId, audioId) {
    const constraints = { audio: { deviceId: audioId }, video: { deviceId: cameraId } };

    window.navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        const { inputVideo, outputVideo } = this.refs;
        let volume;

        // audioContextのインスタンス生成 (Web Audio APIの基底となるクラス)
        const audioContext = new AudioContext();
        // 周波数領域の分析に用いる AnalyserNode を作成
        const audioAnalyser = audioContext.createAnalyser();
        // 高速フーリエ変換のデータサイズ
        // audioAnalyser.fftSize = 128;
        // 波形データを格納する配列を初期化
        const frequencies = new Uint8Array(audioAnalyser.frequencyBinCount);

        // メディアストリームから MediaStreamAudioSourceNode オブジェクトを生成
        // AudioBufferSourceNodeを AnalyserNode に接続
        audioContext.createMediaStreamSource(stream).connect(audioAnalyser);

        const getVolume = () => {
          let volume = 0;

          // 周波数領域の波形データを取得
          audioAnalyser.getByteFrequencyData(frequencies);

          // 人間が発する声の周波数範囲 = 100Hz〜4kHz
          // index * 44100 / 2048 が周波数の為、近似値で5〜185
          for (let frequency = 5; frequency <= 185; frequency++) volume += frequencies[frequency];

          // 180段階(5〜185)の周波数で各々0〜255のデータを持っている為、(180 * 255)で母数を生成
          return Math.floor((volume / (180 * 255)) * 100);
        };

        (volume = () => {
          this.setState({ volume: getVolume() });
          window.requestAnimationFrame(volume);
        })();

        // ステータスの変更
        this.setState({ valid: VALID.STARTUP });

        // デバイスからのストリームオブジェクトをvideoタグに紐付け
        inputVideo.srcObject = stream;
        // 再生
        inputVideo.play();
        // 消音
        inputVideo.muted = true;

        // 録画用APIのインスタンス生成
        this.recorder = new MediaRecorder(stream, RECORDER_OPTIONS);
        // 録画終了時に発火
        this.recorder.addEventListener('dataavailable', (event) => {
          // 録画データをvideoタグに紐付け
          outputVideo.src = window.URL.createObjectURL(event.data);
          // 録画データの自動再生
          outputVideo.play();
        });
      })
      .catch(() =>
        // エラーメッセージ
        this.setState({ message: 'カメラを使用できません' }),
      );
  }

  /**
   *  カメラ・マイク起動
   *  @version 2017/01/05
   *  @author ryo-as
   */
  start() {
    const { deviceVideo, deviceAudio } = this.refs;
    this.initDevice(deviceVideo.value, deviceAudio.value);
  }

  /**
   *  録画
   *  @version 2017/01/05
   *  @author ryo-as
   */
  record() {
    this.recorder.start();
    setTimeout(() => {
      this.recorder.stop();
      this.setState({ valid: VALID.RECORD });
    }, 5000);
  }

  /**
   *  再生
   *  @version 2017/01/05
   *  @author ryo-as
   */
  play() {
    this.refs.outputVideo.play();
  }

  /**
   *  表示処理
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  render() {
    const { valid, message, devices, volume } = this.state;
    const { video, sound, recording } = this.props;

    return (
      <section className={style.section}>
        <h2 className={style.section__heading}>カメラ・マイク</h2>
        <p className={style.section__text}>
          カメラ・マイクのテストを行います（レッスン時にカメラをOFFにすることは可能です）
          <br />
          下記1〜4の手順に従って録画・再生のテストを行ってください。
        </p>

        {message && <p>{message}</p>}

        <div className={style.camera}>
          <div>
            {/* 録画手順 */}
            <ol className={style.camera__arrows}>
              <li className={style.camera__arrow}>
                <h3 className={style.camera__heading}>カメラ・マイク起動ボタンをクリック</h3>
                <ul className={style.camera__list}>
                  <li className={style.camera__item}>カメラが起動すると映像が表示されます</li>
                  <li className={style.camera__item}>マイクが起動するとメーターが動きます</li>
                </ul>
              </li>
              <li className={style.camera__arrow}>
                <h3 className={style.camera__heading}>録画ボタンをクリック</h3>
                <ul className={style.camera__list}>
                  <li className={style.camera__item}>
                    音が反響するのを防止するため
                    <br />
                    録音時は自分の声が聞こえません
                  </li>
                  <li className={style.camera__item}>5秒間録画されます</li>
                </ul>
              </li>
              <li className={style.camera__arrow}>
                <h3 className={style.camera__heading}>再生して確認</h3>
                <ul className={style.camera__list}>
                  <li className={style.camera__item}>録画後、自動的に再生されます</li>
                  <li className={style.camera__item}>再生ボタンで何度でも再生できます</li>
                </ul>
              </li>
            </ol>

            {/* 録画機能 */}
            <div className={style.video}>
              <ul className={style.video__displays}>
                <li className={style.video__display}>
                  <video ref='inputVideo' className={style.video__main} />
                  <Gauge {...{ volume }} />
                </li>
                <li className={style.video__display}>
                  <video ref='outputVideo' className={style.video__main} />
                </li>
              </ul>

              <ol className={style.video__buttons}>
                <li className={style.video__button}>
                  <a
                    className={valid >= VALID.DEVISES ? style.video__buttonLink : style.video__buttonDisabled}
                    onClick={this.start.bind(this)}
                  >
                    カメラ・マイク起動
                  </a>
                </li>
                <li className={style.video__button}>
                  <a
                    className={valid >= VALID.STARTUP ? style.video__buttonLink : style.video__buttonDisabled}
                    onClick={this.record.bind(this)}
                  >
                    録画
                  </a>
                </li>
                <li className={style.video__button}>
                  <a
                    className={valid >= VALID.RECORD ? style.video__buttonLink : style.video__buttonDisabled}
                    onClick={this.play.bind(this)}
                  >
                    再生
                  </a>
                </li>
              </ol>

              {devices && (
                <div className={style.device}>
                  <div className={style.device__container}>
                    <dl className={style.device__item}>
                      <dt className={style.device__label}>カメラ</dt>
                      <dd className={style.device__pulldown}>
                        {devices.videoinput ? (
                          <select ref='deviceVideo' onChange={this.start.bind(this)} className={style.device__select}>
                            {devices.videoinput.map((device, key) => (
                              <option value={device.deviceId} key={key}>
                                {device.label}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <div className={style.device__notice}>デバイス情報が取得できませんでした</div>
                        )}
                      </dd>
                    </dl>
                    {!devices.videoinput && (
                      <a href='#camera' className={style.cameraCheck__note}>
                        カメラが起動しない
                      </a>
                    )}
                  </div>

                  <div className={style.device__container}>
                    <dl className={style.device__item}>
                      <dt className={style.device__label}>マイク</dt>
                      <dd className={style.device__pulldown}>
                        {devices.audioinput ? (
                          <select ref='deviceAudio' onChange={this.start.bind(this)} className={style.device__select}>
                            {devices.audioinput.map((device, key) => (
                              <option value={device.deviceId} key={key}>
                                {device.label}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <div className={style.device__notice}>デバイス情報が取得できませんでした</div>
                        )}
                      </dd>
                    </dl>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* 録画チェック */}
          <div className={style.cameraCheck}>
            <h3 className={style.cameraCheck__heading}>下記項目をチェック</h3>
            <ul className={style.cameraCheck__list}>
              <li className={style.cameraCheck__item}>
                <p className={style.cameraCheck__question}>カメラからの映像は表示されましたか？</p>
                <label className='c-form-radioLabel'>
                  <Field value='enabled' name='video' component='input' type='radio' className='c-form-radio' />
                  <i className='c-form-radioIcon' />
                  はい
                </label>
                <label className='c-form-radioLabel'>
                  <Field value='disabled' name='video' component='input' type='radio' className='c-form-radio' />
                  <i className='c-form-radioIcon' />
                  いいえ
                </label>

                {video === 'disabled' && (
                  <a href='#camera' className={style.cameraCheck__note}>
                    カメラが起動しない
                  </a>
                )}
              </li>
              <li className={style.cameraCheck__item}>
                <p className={style.cameraCheck__question}>音量メーターは反応しましたか？</p>
                <label className='c-form-radioLabel'>
                  <Field value='enabled' name='sound' component='input' type='radio' className='c-form-radio' />
                  <i className='c-form-radioIcon' />
                  はい
                </label>
                <label className='c-form-radioLabel'>
                  <Field value='disabled' name='sound' component='input' type='radio' className='c-form-radio' />
                  <i className='c-form-radioIcon' />
                  いいえ
                </label>
                {sound === 'disabled' && (
                  <a href='#sound1' className={style.cameraCheck__note}>
                    音量メーターが反応しない
                  </a>
                )}
              </li>
              <li className={style.cameraCheck__item}>
                <p className={style.cameraCheck__question}>再生時、音は聞こえましたか？</p>
                <label className='c-form-radioLabel'>
                  <Field value='enabled' name='recording' component='input' type='radio' className='c-form-radio' />
                  <i className='c-form-radioIcon' />
                  はい
                </label>
                <label className='c-form-radioLabel'>
                  <Field value='disabled' name='recording' component='input' type='radio' className='c-form-radio' />
                  <i className='c-form-radioIcon' />
                  いいえ
                </label>
                {recording === 'disabled' && (
                  <a href='#sound2' className={style.cameraCheck__note}>
                    再生時に音が出ない
                  </a>
                )}
              </li>
            </ul>
          </div>
        </div>
      </section>
    );
  }
}
