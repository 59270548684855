// import modules
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

// import view component.
import Template from '../presentationals/input.jsx';

/**
 * Chrome判定
 * @const {object}
 */
const isChrome = (() => {
  // UA取得
  const userAgent = window.navigator.userAgent.toLowerCase();
  // Android
  if (userAgent.indexOf('android') !== -1) return false;
  // iPhone
  if (userAgent.indexOf('iphone') !== -1) return false;
  // IE series
  if (userAgent.indexOf('msie') !== -1 || userAgent.indexOf('trident') !== -1) return false;
  // Edge
  if (userAgent.indexOf('edge') !== -1) return false;
  // Chrome
  if (userAgent.indexOf('chrome') !== -1) return true;
  // 判別不可
  return false;
})();

/**
 * Action to Props
 * @const {object}
 */
const mapDispatchToProps = () => ({});

/**
 * State to Props
 * @const {object}
 */
const mapStateToProps = (state) => {
  const selector = formValueSelector('input');

  if (
    isChrome &&
    navigator.cookieEnabled &&
    selector(state, 'video') === 'enabled' &&
    selector(state, 'sound') === 'enabled' &&
    selector(state, 'recording') === 'enabled'
  )
    location.href = '/env_check/confirm';

  return {
    browser: {
      isChrome,
      enabledCookie: navigator.cookieEnabled,
    },
    camera: {
      video: selector(state, 'video'),
      sound: selector(state, 'sound'),
      recording: selector(state, 'recording'),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Template);
