import Rails from '@rails/ujs';
Rails.start();
import 'components/bugsnag.es6';
import mount from 'components/mount';

// アラート
import Alert from 'components/alert';
// 立ち読み
import Browse from 'products/components/browse';
// コース申し込み
import CoursesEntry from 'products/components/course/entry';
// 環境チェック
import EnvCheck from 'products/components/env_check';
// メール認証後の登録
import Register from 'products/components/session/register';
// パスワードリセット
import SessionRemind from 'products/components/session/remind';
// パスワード再設定
import SessionResetPassword from 'products/components/session/reset_password';
// ログイン
import SessionSignIn from 'products/components/session/sign_in';
// 新規登録
import SessionSignUp from 'products/components/session/sign_up';
// 講師
import Teachers from 'products/components/teachers';

mount({
  Alert,
  Browse,
  CoursesEntry,
  EnvCheck,
  Register,
  SessionRemind,
  SessionResetPassword,
  SessionSignIn,
  SessionSignUp,
  Teachers,
});

// スクロールするとヘッダーの高さを変更
import 'products/scripts/fixed_header';
// 上からヘッダーが降りてくる(SP)
import 'products/scripts/fixed_header_sp';
// SPのメニュー開く
import 'scripts/drawer';
// ページ遷移後スクロール
import 'products/scripts/transited_scroll';
// アコーディオン
import 'scripts/accordion';
// アコーディオン
import 'products/scripts/accordion_new';
// 縦書き処理
import 'products/scripts/vertical';
// 透過フッターの表示・非表示
import 'scripts/footer_fadeout';
// 汎用クリックイベント
import 'scripts/click_event';
