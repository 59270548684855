// import modules
import EventEmitter from 'eventemitter3';
import dispatcher from 'components/dispatcher';

// import properties
import { EVENT_TYPE } from './properties.es6';

/**
 *  Store Class
 *  @version 2017/01/05
 *  @author ryo-as
 */
class Store extends EventEmitter {
  /**
   *  コンストラクタ
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  constructor(dispatcher) {
    super();
    // Initialize Data
    this.state = this.getDataSchema();
    // Regist Handlers
    dispatcher.register(this.handlers.bind(this));
  }

  /**
   *  データ構造体を取得
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  getDataSchema() {
    return {
      authenticityToken: null,
      keyPhrase: null,
      value: {
        password: null,
        confirm: null,
      },
      error: {
        password: null,
        confirm: null,
      },
      unvalid: true,
    };
  }

  /**
   *  状態の取得
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  getState() {
    return this.state;
  }

  /**
   *  リスナーの登録
   *
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  addListener(callback) {
    this.on(EVENT_TYPE, callback);
  }

  /**
   *  リスナーの削除
   *
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  removeListener(callback) {
    this.removeListener(EVENT_TYPE, callback);
  }

  /**
   *  イベントの実行
   *
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  emitChange() {
    this.emit(EVENT_TYPE);
  }

  /**
   *  ハンドラー
   *
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  handlers(action) {
    switch (action.actionType) {
      case `${EVENT_TYPE}:INIT`:
        this.state.authenticityToken = action.authenticityToken;
        this.state.keyPhrase = action.keyPhrase;
        break;

      case `${EVENT_TYPE}:VALIDATE_PASSWORD`:
        this.state.value.password = action.value;
        this.state.error.password = action.error;
        this.state.unvalid = this.isUnvalid();
        break;

      case `${EVENT_TYPE}:VALIDATE_CONFIRM`:
        this.state.value.confirm = action.confirm;
        this.state.error.confirm = action.error;
        this.state.unvalid = this.isUnvalid();
        break;
    }

    // 更新
    this.emitChange();
  }

  /**
   *  エラーチェックを行い無効の場合にtrueを返す
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  isUnvalid() {
    return Object.keys(this.state.error).some((key) => {
      return this.state.error[key] !== false;
    });
  }
}

export default new Store(dispatcher);
