// import modules
import { Component } from 'react';
import { connect } from 'react-redux';
// import style
import * as styleDesktop from '../style.module.sass';
import * as styleSp from '../style.sp.module.sass';
// import utilities
import { PAYMENT_PATH } from '../utilities.es6';

/**
 *  申し込み内容（コース）
 *  @version 2019/11/14
 */
class Cart extends Component {
  /**
   *  コンストラクタ
   *  @version 2019/11/14
   */
  constructor(props) {
    super(props);
    this.state = { amount: 0 };
  }

  /**
   *  画面更新時に実行
   *  @version 2019/11/14
   */
  componentDidUpdate(prevProps) {
    const { cart } = this.props;
    const prev = (prevProps.cart && prevProps.cart.total_amount_tax) || 0;
    const current = (cart && cart.total_amount_tax) || 0;
    const startTime = Date.now();
    const DURATION = 400;
    if (prev !== current) {
      const timer = setInterval(() => {
        const elapseTime = Date.now() - startTime;
        const progress = elapseTime / DURATION;
        if (progress < 1) {
          this.setState({ amount: Math.floor(prev + progress * (current - prev)) });
        } else {
          this.setState({ amount: current });
          clearInterval(timer);
        }
      }, 16);
    }
  }

  /**
   *  表示処理
   *  @version 2019/11/14
   */
  render() {
    const { course, plan, constants, device, cart, style } = this.props;
    const { amount } = this.state;

    return (
      <div className={style.cart}>
        {device === 'sp' && <span className={style.cart__text}>お申し込み内容</span>}
        <div className={style.cart__wrap}>
          <div className={style.cart__heading}>
            <p className={style.cart__course}>{course && `${course.name}コース`}</p>
            <div className={style.cart__planWrap}>
              <p className={style.cart__plan}>{plan && plan.name}</p>
            </div>
            {plan && (
              <p className={style.cart__info}>
                受講期間：{plan.lesson_period}　レッスン回数：{plan.lesson_count}
              </p>
            )}
          </div>
          <div className={style.cart__inner}>
            <ul className={style.cart__list}>
              <li className={style.cart__item}>
                <span>
                  受講期限
                  {cart &&
                    (device === 'sp' ? (
                      <span className='u-fs-10'>※</span>
                    ) : (
                      <span
                        className={style.cart__tooltip}
                        data-tooltip='表示の日付は、本日から受講開始した場合の受講期限です。お支払い方法が銀行振込・CodeCamp分割（銀行振込）・提携ローンの場合、お申し込み完了から受講開始までにお手続きのお時間がかかります。'
                      >
                        ？
                      </span>
                    ))}
                </span>
                <span>{cart ? plan.temporary_limit_time : 'ー'}</span>
              </li>
              {!constants.comission_fee ? (
                <li className={style.cart__item}>
                  <span>入学金</span>
                  <span>{cart ? `${Number(constants.admission_fee).toLocaleString()}円` : 'ー'}</span>
                </li>
              ) : (
                <li className={style.cart__item}>
                  <span>事務手数料</span>
                  <span>{cart ? `${Number(constants.comission_fee).toLocaleString()}円` : 'ー'}</span>
                </li>
              )}
              <li className={style.cart__item}>
                <span>受講料</span>
                <span>{cart ? `${Number(plan.price).toLocaleString()}円` : 'ー'}</span>
              </li>
              <li className={style.cart__item}>
                <span>消費税</span>
                <span>{cart ? `${cart.tax.toLocaleString()}円` : 'ー'}</span>
              </li>
              <li className={style.cart__total}>
                <span className={style['caption']}>合計金額 (税込)</span>
                <span className={style['amount']}>
                  {cart ? amount.toLocaleString() : <span className='u-fs-16'>ー</span>}
                  <span className='u-fs-14 u-ml-5'>円</span>
                </span>
              </li>
            </ul>

            {/* ボタン */}
            <div>
              <a
                href={plan && plan.id ? `${PAYMENT_PATH}${plan.id}` : ''}
                className={`${style.cart__button} c-btnMain-primaryB c-btnSize-60 u-fs-20`}
                disabled={!plan}
              >
                お支払い情報へ進む
              </a>
              <div className={style.fixed__buttonWrap}>
                <a
                  href={plan && plan.id ? `${PAYMENT_PATH}${plan.id}` : ''}
                  className={`${style.fixed__button} c-btnMain-primaryB c-btnSize-60 u-fs-20`}
                  disabled={!plan}
                >
                  お支払い情報へ進む
                </a>
              </div>
            </div>
          </div>
        </div>
        {device === 'sp' && (
          <>
            <p className={style.cart__note}>
              表示の日付は、本日から受講開始した場合の受講期限です。
              <br />
              お支払い方法が銀行振込・CodeCamp分割（銀行振込）・提携ローンの場合、お申し込み完了から受講開始までにお手続きのお時間がかかります。
            </p>
          </>
        )}
      </div>
    );
  }
}

/**
 * State to Props
 * @const {object}
 */
const mapStateToProps = ({ entries }) => ({
  ...entries,
  style: entries.device.match(/pc/) ? styleDesktop : styleSp,
});

export default connect(mapStateToProps)(Cart);
