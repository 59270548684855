// import modules
import EventEmitter from 'eventemitter3';
import dispatcher from 'components/dispatcher';

// import properties
import { EVENT_TYPE } from './properties.es6';

/**
 *  Store Class
 *  @version 2017/07/11
 *  @author ayana.kusu
 */
class Store extends EventEmitter {
  /**
   *  コンストラクタ
   *  @version 2017/07/11
   *  @author ayana.kusu
   */
  constructor(dispatcher) {
    super();
    // Initialize Data
    this.state = this.getDataSchema();
    // Regist Handlers
    dispatcher.register(this.handlers.bind(this));
  }

  /**
   *  データ構造体を取得
   *  @version 2017/07/11
   *  @author ayana.kusu
   */
  getDataSchema() {
    return {
      authenticity_token: null,
      value: {
        email: null,
      },
      error: {
        email: null,
      },
      valid: null,
      referrer: null,
    };
  }

  /**
   *  状態の取得
   *  @version 2017/07/11
   *  @author ayana.kusus
   */
  getState() {
    return this.state;
  }

  /**
   *  リスナーの登録
   *  @version 2017/07/11
   *  @author ayana.kusu
   */
  addListener(callback) {
    this.on(EVENT_TYPE, callback);
  }

  /**
   *  リスナーの削除
   *  @version 2017/05/12
   *  @author ayana.kusu
   */
  removeListener(callback) {
    this.removeListener(EVENT_TYPE, callback);
  }

  /**
   *  イベントの実行
   *  @version 2017/07/11
   *  @author ayana.kusu
   */
  emitChange() {
    this.emit(EVENT_TYPE);
  }

  /**
   *  ハンドラー
   *  @version 2017/07/11
   *  @author ayana.kusu
   */
  handlers(action) {
    switch (action.actionType) {
      case `${EVENT_TYPE}:INIT`:
        this.state.authenticity_token = action.authenticity_token;
        this.state.referrer = action.referrer;
        break;

      case `${EVENT_TYPE}:VALIDATE`:
        this.state.value = action.value;
        this.state.error = action.error;
        this.state.valid = action.valid;
        break;
    }

    // 更新
    this.emitChange();
  }
}

export default new Store(dispatcher);
