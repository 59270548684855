// import modules
import { Link } from 'react-router-dom';

// import css
import * as style from '../style.module.sass';

/**
 *  ルート
 *  @version 2017/01/05
 *  @author ryo-as
 */
export default () => (
  <div className={style.confirm}>
    <section className={style.confirm__container}>
      <h2 className={style.confirm__heading}>環境チェック</h2>
      <div className={style.confirm__inner}>
        <div>
          <span className={style.confirm__icon}>OK</span>
          <p className={style.confirm__text}>
            動作検証が
            <br />
            すべて通りました
          </p>

          <Link to='/env_check' className={style.confirm__close}>
            ×
          </Link>
          <a href='/schedules/trial' className={style.confirm__link}>
            無料カウンセリングを予約する
          </a>
        </div>
      </div>
    </section>
  </div>
);
