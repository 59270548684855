// import modules
import { Component } from 'react';

// import action
import actions from '../action.es6';

/**
 *  教科書一覧
 *  @version 2017/01/05
 *  @author ryo-as
 */
export default class Books extends Component {
  /**
   *  教科書更新
   *  @version 2017/09/26
   *  @author ryo-as
   */
  onChange() {
    actions.fetchBrowse(this.refs.book.value);
  }

  /**
   *  表示処理
   *  @version 2017/01/05
   *  @author ryo-as
   */
  render() {
    const { books, activeId } = this.props;

    return (
      <div className={`p-browse_books ${activeId ? 'p-browse_books--selected' : ''}`}>
        <p>こちらで言語を選択できます</p>
        <div className='p-browse_books__curriculum c-generalForm_selectWrap'>
          <select
            defaultValue={this.props.textbookId}
            className='c-generalForm_select'
            ref='book'
            onChange={this.onChange.bind(this)}
          >
            {books.map((book, key) => {
              return (
                <option value={book.id} key={key}>
                  {book.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  }
}
