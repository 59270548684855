// 画像パス
export const moduleCacheResource = 'https://s3-ap-northeast-1.amazonaws.com/codecamp-cache/students/modules/payments';
// コース分類
export const courseTypeTranslates = {
  ordermade: 'お得なコース',
  master: 'プログラミング系コース',
  others: 'デザイン系コース',
};

// 遷移先URL
export const PAYMENT_PATH = '/students/payments?item_id=';
