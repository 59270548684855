import { Component } from 'react';
import store from './store.es6';
import actions from './action.es6';
import Container from './container.jsx';

/**
 *  SessionSignUp
 *  @version 2017/07/11
 *  @author ayana.kusu
 */
class SessionSignUp extends Component {
  /**
   *  コンストラクタ
   *  @version 2017/07/11
   *  @author ayana.kusu
   */
  constructor(props) {
    super(props);
    this.state = store.getState();
  }

  /**
   *  初期描画直後に一度だけ実行
   *  @version 2017/07/11
   *  @author ayana.kusu
   */
  UNSAFE_componentWillMount() {
    actions.initialize(this.props);
  }

  /**
   *  初期描画直後に一度だけ実行
   *  @version 2017/07/11
   *  @author ayana.kusu
   */
  componentDidMount() {
    store.addListener(this._onChange.bind(this));
  }

  /**
   *  アンマウント直前に実行
   *  @version 2017/07/11
   *  @author ayana.kusu
   */
  componentWillUnmount() {
    store.removeListener(this._onChange.bind(this));
  }

  /**
   *  データのアップデート
   *  @version 2017/07/11
   *  @author ayana.kusu
   */
  _onChange() {
    this.setState(store.getState());
  }

  /**
   *  表示処理
   *  @version 2017/07/11
   *  @author ayana.kusu
   */
  render() {
    return <Container {...this.state} />;
  }
}

export default SessionSignUp;
