// import modules
import { connect } from 'react-redux';
// import components and containers
import Modal from './modal';
// import actions
import { fetchProfile } from '../action.es6';
// import style
import * as styleDesktop from '../style.module.sass';
import * as styleSp from '../style.sp.module.sass';

const Template = ({ teachers, teacher, modal, device, fetchProfile }) => {
  const style = device.match(/pc/) ? styleDesktop : styleSp;

  return (
    <div>
      {modal && teacher && <Modal {...{ teacher }} />}
      <ul className={style.teacher}>
        {teachers.map((teacher, key) => {
          return (
            <li className={style.teacher__item} key={key}>
              <figure className={style.teacher__figure} onClick={() => fetchProfile(teacher.id)}>
                <img src={teacher.image} alt={teacher.name} className={style.teacher__image} />
              </figure>
              <figcaption className={style.teacher__name}>{teacher.name}</figcaption>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const mapStateToProps = ({ teachers }) => ({
  ...teachers,
});

export default connect(mapStateToProps, { fetchProfile })(Template);
