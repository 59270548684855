/**
 *  Method Name
 *  @const {object}
 */
export const INITIALIZE = 'INITIALIZE';

/**
 *  Action Method
 *  @const {object}
 *  @version 2016/05/02
 *  @author ryo.aso
 */
export const initialize = (props) => ({
  type: INITIALIZE,
  props,
});
