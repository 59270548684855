// extracted by mini-css-extract-plugin
var _1 = "admission__oQIS0";
var _2 = "amount__CdXfI";
var _3 = "caption__M690h";
var _4 = "cart__b8fvv";
var _5 = "cart__button__RLKj6";
var _6 = "cart__course__Xww3D";
var _7 = "cart__curriculums__g8m7I";
var _8 = "cart__heading__kEoFh";
var _9 = "cart__info__NV1SO";
var _a = "cart__inner__MAqQp";
var _b = "cart__item__j1NT5";
var _c = "cart__note__WVoUt";
var _d = "cart__plan__YGu9a";
var _e = "cart__planWrap__HZHVK";
var _f = "cart__tooltip__cIOYj";
var _10 = "cart__tooltipUnder__izzWR";
var _11 = "cart__total__j0I96";
var _12 = "cart__wrap__ymkXd";
var _13 = "container__q3dp1";
var _14 = "courses__category__MMCB9";
var _15 = "courses__heading__Gs0rH";
var _16 = "courses__icon__Op8Cj";
var _17 = "courses__inner__nMn2U";
var _18 = "courses__item__wopYD";
var _19 = "courses__label__C4kVG";
var _1a = "courses__list__V4_Gl";
var _1b = "courses__name__nqoJ9";
var _1c = "courses__title__U22GX";
var _1d = "fixed__button__g5PaI";
var _1e = "fixed__buttonWrap__bQTXL";
var _1f = "header__yUUEa";
var _20 = "header__title__oUPSj";
var _21 = "left__gBhwv";
var _22 = "plans__TMTBF";
var _23 = "plans__data__oSLaf";
var _24 = "plans__icon__WF_h3";
var _25 = "plans__info__qDsdh";
var _26 = "plans__item__cz0x8";
var _27 = "plans__label__G0cMq";
var _28 = "plans__name__SCPAB";
var _29 = "pristine__ommri";
export { _1 as "admission", _2 as "amount", _3 as "caption", _4 as "cart", _5 as "cart__button", _6 as "cart__course", _7 as "cart__curriculums", _8 as "cart__heading", _9 as "cart__info", _a as "cart__inner", _b as "cart__item", _c as "cart__note", _d as "cart__plan", _e as "cart__planWrap", _f as "cart__tooltip", _10 as "cart__tooltipUnder", _11 as "cart__total", _12 as "cart__wrap", _13 as "container", _14 as "courses__category", _15 as "courses__heading", _16 as "courses__icon", _17 as "courses__inner", _18 as "courses__item", _19 as "courses__label", _1a as "courses__list", _1b as "courses__name", _1c as "courses__title", _1d as "fixed__button", _1e as "fixed__buttonWrap", _1f as "header", _20 as "header__title", _21 as "left", _22 as "plans", _23 as "plans__data", _24 as "plans__icon", _25 as "plans__info", _26 as "plans__item", _27 as "plans__label", _28 as "plans__name", _29 as "pristine" }
