import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';

import { INITIALIZE } from './action.es6';

/**
 *  システム向け
 *  @version 2017/01/05
 *  @author ryo-as
 */
const system = (
  state = {
    inputs: {},
    coupons: [],
  },
  action,
) => {
  switch (action.type) {
    // 初期値
    case INITIALIZE:
      return action.props;

    default:
      return state;
  }
};

const createRootReducer = (history) =>
  combineReducers({
    system,
    form: formReducer,
    router: connectRouter(history),
  });

export default createRootReducer;
