import { Component } from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { initialize } from './action.es6';
import reducer from './reducer.es6';
import App from './containers/app';

/**
 * Create Store
 * @const {object}
 */
const store = configureStore({
  reducer,
});

/**
 *  Application Class
 *  @version 2018/09/31
 *  @author ayana.kusu
 */
class Teachers extends Component {
  /**
   *  初期描画直後に一度だけ実行
   *  @version 2018/09/31
   */
  UNSAFE_componentWillMount() {
    store.dispatch(initialize(this.props));
  }

  /**
   *  表示処理
   *  @version 2018/09/31
   */
  render() {
    return (
      <Provider {...{ store }}>
        <App />
      </Provider>
    );
  }
}

export default Teachers;
