// import modules
import dispatcher from 'components/dispatcher';

// import properties
import { EVENT_TYPE } from './properties.es6';

/**
 *  Action Class
 *  @version 2017/01/05
 *  @author ryo-as
 */
class Action {
  /**
   *  コンストラクタ
   *  @version 2017/01/05
   *  @author ryo-as
   */
  constructor(dispatcher) {
    this.dispatcher = dispatcher;
  }

  /**
   *  ディスパッチ実行
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  dispatch(params) {
    this.dispatcher.dispatch(params);
  }

  /**
   * 初期処理
   * @version 2017/01/12
   * @author ryo.aso
   * @param {Object} articles - 記事情報
   */
  initialize(props) {
    this.dispatch({
      actionType: `${EVENT_TYPE}:INIT`,
      ...props,
    });
  }

  /**
   * バリデート
   * @version 2017/01/12
   * @author ryo.aso
   * @param {Object} articles - 記事情報
   */
  validatePassword(value) {
    const error = value.length > 7 ? false : '8文字以上で入力してください';

    this.dispatch({
      actionType: `${EVENT_TYPE}:VALIDATE_PASSWORD`,
      ...{ value, error },
    });
  }

  /**
   * バリデート
   * @version 2017/01/12
   * @author ryo.aso
   * @param {Object} articles - 記事情報
   */
  validateConfirm(confirm, password) {
    let error = confirm.length > 7 ? false : '8文字以上で入力してください';
    if (error === false) {
      error = confirm === password ? false : 'パスワードが一致しません';
    }

    this.dispatch({
      actionType: `${EVENT_TYPE}:VALIDATE_CONFIRM`,
      ...{ confirm, error },
    });
  }
}

export default new Action(dispatcher);
