// import modules
import { reduxForm } from 'redux-form';

// import css
import Browser from './browser.jsx';
import Camera from './camera.jsx';
import TroubleShooting from './trouble_shooting.jsx';
import AccountSetup from './account_setup.jsx';

/**
 *  ルート
 *  @version 2017/01/05
 *  @author ryo-as
 */
const Form = ({ handleSubmit, browser, camera }) => (
  <div>
    {/* ブラウザチェック */}
    <Browser {...browser} />

    <form onSubmit={handleSubmit}>
      <Camera {...camera} />
    </form>

    <AccountSetup />

    <TroubleShooting />
  </div>
);

export default reduxForm({
  form: 'input',
  destroyOnUnmount: true,
})(Form);
