// import modules
import { Component } from 'react';

// import action
import actions from '../action.es6';

/**
 *  教科書一覧
 *  @version 2017/01/05
 *  @author ryo-as
 */
export default class Index extends Component {
  /**
   *  表示処理
   *  @version 2017/01/05
   *  @author ryo-as
   */
  render() {
    const { index, activeId } = this.props;

    return (
      <ul className={`p-browse_index ${activeId ? 'p-browse_index--selected' : ''}`}>
        {index.map((part, key) => {
          return (
            <li className='p-browse_index__part' key={key}>
              <Part {...{ part, activeId }} />
            </li>
          );
        })}
      </ul>
    );
  }
}

/**
 *  教科書一覧
 *  @version 2017/01/05
 *  @author ryo-as
 */
class Part extends Component {
  /**
   *  コンストラクタ
   *  @version 2017/01/05
   *  @author ryo-as
   */
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  /**
   *  アコーディオン表示トグル
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  _changeDisplay() {
    this.setState({ show: !this.state.show });
  }

  /**
   *  表示処理
   *  @version 2017/01/05
   *  @author ryo-as
   */
  render() {
    const { part, activeId } = this.props;

    return (
      <article className={`p-browse_index__part--${this.state.show ? 'show' : 'hide'}`} ref='container'>
        <h2 className='p-browse_index__partTitle' onClick={this._changeDisplay.bind(this)}>
          <svg className='icon'>
            <use xlinkHref='#svg-icn_check' />
          </svg>
          <span className='num'>{part.name}</span>
          <span className='text'>{part.description}</span>
        </h2>
        <ul className='p-browse_index__sections'>
          {part.sections.map((section, key) => {
            return (
              <li
                key={key}
                className={`section ${section.id === activeId ? 'section--active' : ''}`}
                onClick={() => {
                  return actions.pushActiveId(section.id);
                }}
              >
                {section.lock === 'lock_against_free' ? (
                  <img
                    className='icon'
                    src='https://s3-ap-northeast-1.amazonaws.com/codecamp-cache/students/textbooks/icn_textbook_lock.png'
                    width='20px'
                    height='20px'
                  />
                ) : (
                  <svg className='icon'>
                    <use xlinkHref='#svg-icn_check' />
                  </svg>
                )}
                <span className='title'>{section.name}</span>
              </li>
            );
          })}
        </ul>
      </article>
    );
  }
}
