// import modules
import { Component } from 'react';

// import action
import actions from './action.es6';

/**
 *  Container Class
 *  @version 2017/01/05
 *  @author ryo-as
 */
export default class Container extends Component {
  /**
   *  バリデーション
   *  @version 2017/01/05
   *  @author ryo-as
   */
  validatePassword() {
    actions.validatePassword(this.refs.password.value);
  }

  /**
   *  バリデーション
   *  @version 2017/01/05
   *  @author ryo-as
   */
  validateConfirm() {
    const { confirm, password } = this.refs;
    actions.validateConfirm(confirm.value, password.value);
  }

  /**
   *  表示処理
   *  @version 2017/01/05
   *  @author ryo-as
   */
  render() {
    const { authenticityToken, keyPhrase, unvalid, value, error } = this.props;

    return (
      <form
        className='p-sessions_form'
        id='edit_student_32'
        action='/students/password_reset'
        acceptCharset='UTF-8'
        method='post'
      >
        <input type='hidden' name='authenticity_token' value={authenticityToken} />
        <input type='hidden' name='key' id='key' value={keyPhrase} />
        <div className={`p-sessions_formField ${error.password ? 'p-sessions_formField-error' : null} `}>
          <label className='p-sessions_formLabel' htmlFor='student_password'>
            パスワード
          </label>
          {error.password ? <p className='p-sessions_errorText'>{error.password}</p> : null}
          <input
            className='c-generalForm_text'
            placeholder='パスワードは英数字8文字以上です。'
            type='password'
            name='student[password]'
            id='student_password'
            value={value.password}
            ref='password'
            onChange={this.validatePassword.bind(this)}
            onBlur={this.validatePassword.bind(this)}
          />
        </div>
        <div className={`p-sessions_formField ${error.confirm ? 'p-sessions_formField-error' : null} `}>
          <label className='p-sessions_formLabel' htmlFor='student_password_confirmation'>
            パスワード確認
          </label>
          {error.confirm ? <p className='p-sessions_errorText'>{error.confirm}</p> : null}
          <input
            className='c-generalForm_text'
            placeholder='パスワード確認'
            type='password'
            name='student[password_confirmation]'
            id='student_password_confirmation'
            value={value.confirm}
            ref='confirm'
            onChange={this.validateConfirm.bind(this)}
            onBlur={this.validateConfirm.bind(this)}
          />
        </div>
        <div className='p-sessions_formField'>
          <input
            className='c-btnMain-primaryB c-btnSize-60 p-sessions_submit'
            type='submit'
            value='再設定する'
            disabled={unvalid}
          />
        </div>
      </form>
    );
  }
}
