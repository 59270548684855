// スクロールすると上からヘッダーが出て来る
window.addEventListener('load', () => {
  const widgetObject = document.getElementById('js-fixedHeader');
  const targetSection = document.getElementById('js-header');

  if (!widgetObject || !targetSection) return;

  const SCROLL = targetSection.offsetHeight;

  document.addEventListener('scroll', () => {
    widgetObject.dataset.show = (window.scrollY > SCROLL).toString();
  });
});
