// import modules
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
// import actions and reducers
import { pushCourse, pushPlan } from '../action.es6';
// import components and styles
import * as styleDesktop from '../style.module.sass';
import * as styleSp from '../style.sp.module.sass';
import { courseTypeTranslates } from '../utilities.es6';

/**
 *  Courses class
 *  @version 2019/11/14
 *  @author ayana.kusu
 */
const Courses = ({ entryForm, device, courses, pushCourse, pushPlan, style }) => {
  // コース選択前は別スタイルを当てる
  const coursePristine = entryForm && entryForm.values && entryForm.values.course ? null : style.pristine;

  return (
    <form className={style.courses}>
      <p className={style.courses__heading}>コースを選択してください</p>
      {Object.keys(courses).map((type, key) => (
        <div className={style.courses__inner} key={key}>
          <h2 className={style.courses__category}>{courseTypeTranslates[type]}</h2>
          <ul className={style.courses__list}>
            {courses[type].map((course, key) => (
              <li className={style.courses__item} key={key}>
                <div className={`${style.courses__title} ${coursePristine}`}>
                  <Field
                    name='course'
                    component='input'
                    type='radio'
                    value={course.code}
                    className='c-form-radio'
                    id={course.code}
                    onChange={(e) => pushCourse(e.target.value)}
                  />
                  <label className={style.courses__label} htmlFor={course.code}>
                    <span className={style.courses__name}>
                      <i className={`c-form-radioIcon ${style.courses__icon}`} />
                      {course.name}
                      {course.code !== 'python' && 'コース'}
                    </span>
                  </label>
                  <Plans plans={course.plans} {...{ device, pushPlan, style }} />
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </form>
  );
};

/**
 *  plans component
 *  @version 2019/11/14
 *  @author ayana.kusu
 */
const Plans = ({ plans, device, pushPlan, style }) => (
  <ul className={style.plans}>
    {plans.map((plan, key) => (
      <li className={style.plans__item} key={key}>
        <Field
          name='plan'
          component='input'
          type='radio'
          value={String(plan.id)}
          className='c-form-radio'
          id={String(plan.id)}
          onChange={(e) => pushPlan(e.target.value)}
        />
        {device === 'sp' ? (
          <label className={style.plans__label} htmlFor={String(plan.id)}>
            <i className={`c-form-radioIcon ${style.plans__icon}`} />
            <p className={style.plans__name}>{plan.name}</p>
            <dl className={style.plans__info}>
              <dt className={style.plans__title}>受講期間：</dt>
              <dd className={style.plans__data}>{plan.lesson_period}</dd>
              <dt className={style.plans__title}>レッスン回数：</dt>
              <dd className={style.plans__data}>{plan.lesson_count}</dd>
            </dl>
          </label>
        ) : (
          <>
            <label className={style.plans__label} htmlFor={String(plan.id)}>
              <i className={`c-form-radioIcon ${style.plans__icon}`} />
              <p className={style.plans__name}>{plan.name}</p>
            </label>
            <dl className={style.plans__info}>
              <dt className={style.plans__title}>受講期間：</dt>
              <dd className={style.plans__data}>{plan.lesson_period}</dd>
              <dt className={style.plans__title}>レッスン回数：</dt>
              <dd className={style.plans__data}>{plan.lesson_count}</dd>
            </dl>
          </>
        )}
      </li>
    ))}
  </ul>
);

/**
 *  connect redux
 *  @version 2019/11/14
 */
const mapStateToProps = ({ entries, form }) => ({
  ...entries,
  entryForm: form.entry,
  style: entries.device.match(/pc/) ? styleDesktop : styleSp,
});

export default connect(mapStateToProps, { pushCourse, pushPlan })(
  reduxForm({
    form: 'entry',
  })(Courses),
);
