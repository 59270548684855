// import modules
import { Component } from 'react';
import serialize from 'form-serialize'; // @see https://www.npmjs.com/package/form-serialize
// import action
import actions from './action.es6';

/**
 *  Container Class
 *  @version 2017/05/12
 *  @author ayana.kusu
 */
export default class Container extends Component {
  /**
   *  アップデート前に実行
   *  @version 2017/05/12
   *  @author ayana.kusu
   */
  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.valid) this.refs.form.submit();
  }

  /**
   *  バリデート
   *  @version 2017/05/12
   *  @author ayana.kusu
   */
  validate(event) {
    // submit処理を抑制
    event.preventDefault();
    // フォームデータ取得
    actions.validate(serialize(this.refs.form, { hash: true, empty: true }));
  }

  /**
   *  表示処理
   *  @version 2017/05/12
   *  @author ayana.kusu
   */
  render() {
    const { authenticity_token, error, referrer } = this.props;

    return (
      <form
        className='c-generalForm_form p-sessions_form'
        name='login'
        id='new_student'
        action='/students/sign_up'
        acceptCharset='UTF-8'
        method='post'
        ref='form'
      >
        {referrer ? <input name='referrer' type='hidden' value={referrer} /> : ''}
        <input type='hidden' name='authenticity_token' value={authenticity_token} />
        <div className={`p-sessions_formField ${error.email ? 'p-sessions_formField-error' : null} `}>
          {error.email ? <p className='p-sessions_errorText'>{error.email}</p> : null}
          <input
            required='required'
            placeholder='メールアドレスを入力してください'
            className='p-sessions_signUpInput'
            type='text'
            name='student[email]'
            id='student_email'
          />
        </div>
        <div className='p-sessions_formField'>
          <input
            className='p-sessions_signUpButton'
            type='submit'
            value='上記に同意してメールで送信する'
            onClick={this.validate.bind(this)}
          />
        </div>
      </form>
    );
  }
}
