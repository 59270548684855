// import modules
import { Component } from 'react';

// import action
import actions from '../action.es6';

/**
 *  教科書一覧
 *  @version 2017/01/05
 *  @author ryo-as
 */
export default class Control extends Component {
  /**
   *  教科書更新
   *  @version 2017/09/26
   *  @author ryo-as
   */
  _closeBook() {
    actions.dropActiveId();
  }

  /**
   *  表示処理
   *  @version 2017/01/05
   *  @author ryo-as
   */
  render() {
    return (
      <ul className='p-browse_control'>
        <li onClick={this._closeBook.bind(this)} className='p-browse_control__outline'>
          <div>目次へ</div>
        </li>
        <li className='p-browse_control__signin'>
          <a href='/schedules/trial?referrer=browse' className='c-btnSize-50 c-btnMain-primaryA'>
            無料カウンセリングはこちら
          </a>
        </li>
      </ul>
    );
  }
}
