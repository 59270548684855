// import modules
import { Component } from 'react';
import { connect } from 'react-redux';
// import actions
import { offModal } from '../action.es6';

/**
 *  App
 *  @version 2018/09/31
 *  @author ayana.kusu
 */
class Modal extends Component {
  /**
   *  親要素のクリックイベントを引き継がない
   *  @version 2017/10/15
   *  @author ayana.kusu
   */
  _stopPropagation(event) {
    event.stopPropagation();
  }

  /**
   *  改行コードを改行タグ変換
   *  @version 2017/10/15
   *  @author ayana.kusu
   *  @param {String} string - 変換前の文字列
   *  @return {String} 変換後の文字列
   */
  _changeLineFeedCode(string) {
    if (!string) return null;
    return string.replace(/\n/g, '<br />');
  }

  /**
   *  タグをエスケープ
   *  @version 2017/04/12
   *  @author ayana.kusu
   *  @param {String} string - 変換前の文字列
   *  @return {String} 変換後の文字列
   */
  _escapeHtml(string) {
    return string.replace(/[&'`"<>]/g, (match) => {
      return {
        '&': '&amp;',
        "'": '&#x27;',
        '`': '&#x60;',
        '"': '&quot;',
        '<': '&lt;',
        '>': '&gt;',
      }[match];
    });
  }

  /**
   *  表示処理
   *  @version 2017/10/15
   *  @author ayana.kusu
   */
  render() {
    const { teacher, curriculums, features, badge } = this.props.teacher;
    const { offModal } = this.props;

    if (!teacher) return false;

    const style = { backgroundImage: `url(${teacher.picture})` };

    return (
      <div className='c-modal'>
        <div className='c-modal__overlay' onClick={offModal}>
          <div className='c-modal__wrap c-teacherProfile' onClick={this._stopPropagation.bind(this)}>
            <div className='c-teacherProfile__inner'>
              <i className='c-userImage--circle c-userImage--180' {...{ style }}>
                {teacher.name}
              </i>
              <p className='c-teacherProfile-name'>
                <ruby>
                  {teacher.name}
                  <rt>{teacher.kana}</rt>
                </ruby>
              </p>
              {badge.name ? (
                <div className='c-teacherProfile-badge__wrap'>
                  <i className={`c-teacherProfile-badge__image--${badge.name}`}>{badge.info.caption}</i>
                  <ul className='c-teacherProfile-badge__list'>
                    {badge.info.definition.map((def, key) => {
                      return (
                        <li className='c-teacherProfile-badge__item' key={key}>
                          {def}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className='c-teacherProfile__inner'>
              <div className='c-teacherProfile__infoWrap'>
                <dl className='c-teacherProfile__info'>
                  <dt className='c-teacherProfile__term'>担当</dt>
                  <dd className='c-teacherProfile__definition'>
                    {curriculums.length ? curriculums.join('、') : '担当なし'}
                  </dd>
                </dl>
                <dl className='c-teacherProfile__info c-teacherProfile__info--feature'>
                  <dt className='c-teacherProfile__term c-teacherProfile__term--feature'>特徴</dt>
                  <dd className='c-teacherProfile__definition'>
                    <ul className='c-teacherProfile__features'>
                      {features.length
                        ? features.map((feature, key) => {
                            return (
                              <li className='c-teacherProfile__feature' key={key}>
                                <img
                                  src={`https://s3-ap-northeast-1.amazonaws.com/codecamp-cache/models/feature/feature_${feature.id}.png`}
                                />
                                {feature.name}
                              </li>
                            );
                          })
                        : null}
                      {badge.name ? (
                        <li className='c-teacherProfile__feature c-teacherProfile__feature--badge'>
                          <img
                            src={`https://s3-ap-northeast-1.amazonaws.com/codecamp-cache/students/component/teacher_badge/${badge.name}.png`}
                          />
                          {badge.info.caption}
                        </li>
                      ) : (
                        ''
                      )}
                    </ul>
                    {!badge.name && !features.length ? '設定なし' : ''}
                  </dd>
                </dl>
                <dl className='c-teacherProfile__info c-teacherProfile__info--comment'>
                  <dt className='c-teacherProfile__term'>講師からのコメント</dt>
                  <dd className='c-teacherProfile__definition'>
                    {teacher.comment ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this._changeLineFeedCode(this._escapeHtml(teacher.comment)),
                        }}
                      ></p>
                    ) : (
                      'コメントなし'
                    )}
                  </dd>
                </dl>
              </div>
              <nav>
                <span onClick={offModal} className='c-modal__close c-teacherProfile__closeLink'>
                  閉じる
                </span>
              </nav>
            </div>
            <div onClick={offModal} className='c-teacherProfile__close'>
              ×
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/**
 *  connect redux
 *  @version 2018/09/31
 */
const mapStateToProps = ({ teachers }) => ({
  ...teachers,
});

export default connect(mapStateToProps, { offModal })(Modal);
