// import modules
import EventEmitter from 'eventemitter3';
import dispatcher from 'components/dispatcher';

// import properties
import { EVENT_TYPE } from './properties.es6';

/**
 *  Store Class
 *  @version 2017/01/05
 *  @author ryo-as
 */
class Store extends EventEmitter {
  /**
   *  コンストラクタ
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  constructor(dispatcher) {
    super();
    // Initialize Data
    this.state = this.getDataSchema();
    // Regist Handlers
    dispatcher.register(this.handlers.bind(this));
  }

  /**
   *  データ構造体を取得
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  getDataSchema() {
    return {
      activeId: null,
      isMobile: false,
      books: [],
      index: [],
      isLoading: false,
    };
  }

  /**
   *  状態の取得
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  getState() {
    return this.state;
  }

  /**
   *  リスナーの登録
   *
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  addListener(callback) {
    this.on(EVENT_TYPE, callback);
  }

  /**
   *  リスナーの削除
   *
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  removeListener(callback) {
    this.removeListener(EVENT_TYPE, callback);
  }

  /**
   *  イベントの実行
   *
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  emitChange() {
    this.emit(EVENT_TYPE);
  }

  /**
   *  ハンドラー
   *
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  handlers(action) {
    switch (action.actionType) {
      // 初期値設定
      case `${EVENT_TYPE}:INIT`:
        // 教科書一覧
        this.state.books = action.props.books;
        this.state.isMobile = action.props.isMobile;
        break;

      // 教科書目次の更新
      case `${EVENT_TYPE}:FETCH_INDEX`:
        // 教科書目次
        this.state.index = action.index;
        // 読み込み非表示
        this.state.isLoading = false;
        // 選択説を初期化
        this.state.activeId = null;
        break;

      // 読み込み表示
      case `${EVENT_TYPE}:PUSH_ACTIVE_ID`:
        this.state.activeId = action.id;
        break;

      case `${EVENT_TYPE}:DROP_ACTIVE_ID`:
        this.state.activeId = null;
        break;

      // 読み込み表示
      case `${EVENT_TYPE}:PUSH_LOADING`:
        this.state.isLoading = true;
        break;

      // 読み込み非表示
      case `${EVENT_TYPE}:DROP_LOADING`:
        this.state.isLoading = false;
        break;
    }

    // 更新
    this.emitChange();
  }
}

export default new Store(dispatcher);
