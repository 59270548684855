// import modules
import dispatcher from 'components/dispatcher';
import validator from 'validator'; // @see https://www.npmjs.com/package/validator

// import properties
import { EVENT_TYPE } from './properties.es6';

/**
 *  Action Class
 *  @version 2017/07/11
 *  @author ayana.kusu
 */
class Action {
  /**
   * コンストラクタ
   * @version 2017/07/11
   * @author ayana.kusu
   */
  constructor(dispatcher) {
    this.dispatcher = dispatcher;
  }

  /**
   * ディスパッチ実行
   * @version 2017/07/11
   * @author ayana.kusu
   */
  dispatch(params) {
    this.dispatcher.dispatch(params);
  }

  /**
   * 初期処理
   * @version 2017/07/11
   * @author ayana.kusu
   * @param {Object} articles - 記事情報
   */
  initialize(props) {
    this.dispatch({
      actionType: `${EVENT_TYPE}:INIT`,
      ...props,
    });
  }

  /**
   * 確認
   * @version 2017/07/11
   * @author ayana.kusu
   * @param {Object} params - 入力情報
   */
  validate(params) {
    const { student } = params;

    // エラーメッセージ
    const error = {
      // 名前
      name: validator.isLength(student['name'].trim(), { min: 2, max: 255 }) ? null : '名前を入力してください',
      // メールアドレス
      email: validator.isEmail(student['email'].trim()) ? null : 'メールアドレスを入力してください',
      // パスワード
      password: validator.isLength(student['password'].trim(), { min: 8, max: 255 })
        ? null
        : '8文字以上で入力してください',
      // パスワード確認
      confirm: student['password'] === student['password_confirmation'] ? null : 'パスワードが一致しません',
    };

    const valid = Object.keys(error).every((key) => {
      return error[key] === null;
    });

    this.dispatch({
      actionType: `${EVENT_TYPE}:VALIDATE`,
      ...{ error },
      ...{ valid },
    });
  }
}

export default new Action(dispatcher);
