/**
 *  アコーディオン
 *  @version 2018/02/02
 *  @author ryo-as
 */
window.addEventListener('load', () => {
  // コンテナ一覧
  const containers = document.querySelectorAll('[data-accordion]');

  if (!containers.length) return;

  containers.forEach((container) => {
    const targets = container.querySelectorAll(container.dataset.accordion);

    targets.forEach((target) => {
      const wrapper = target.parentNode.querySelector(target.dataset.trigger);

      // 初期化
      wrapper.style.display = 'none';
      target.dataset.open = 'close';

      target.addEventListener('click', () => {
        target.dataset.open = target.dataset.open === 'close' ? 'open' : 'close';

        wrapper.style.display = target.dataset.open === 'close' ? 'none' : 'block';
      });
    });
  });
});
