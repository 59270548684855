// import modules
import dispatcher from 'components/dispatcher';
import fetch from 'common/fetch';

import { EVENT_TYPE } from './properties.es6';

/**
 *  Action Class
 *  @version 2017/01/05
 *  @author ryo-as
 */
class Action {
  /**
   *  コンストラクタ
   *  @version 2017/01/05
   *  @author ryo-as
   */
  constructor(dispatcher) {
    this.dispatcher = dispatcher;
  }

  /**
   *  ディスパッチ実行
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  dispatch(params) {
    this.dispatcher.dispatch(params);
  }

  /**
   * 初期処理
   * @version 2017/01/12
   * @author ryo.aso
   * @param {Object} props - 初期データ
   */
  initialize(props) {
    // 初期表示
    this.fetchBrowse(props.bookId);

    this.dispatch({
      actionType: `${EVENT_TYPE}:INIT`,
      ...{ props },
    });
  }

  /**
   * 目次情報の同期
   * @version 2017/09/27
   * @author ryo.aso
   * @param {Number} id - 選択された教科書ID
   */
  fetchBrowse(id) {
    // 読み込み表示
    this.pushLoading();

    fetch(`/browse/outline.json?id=${id}`)
      .then((response) => response.json())
      .then((data) => {
        return this.dispatch({
          actionType: `${EVENT_TYPE}:FETCH_INDEX`,
          index: data.index,
        });
      });
  }

  /**
   * 選択節の通知
   * @version 2017/09/27
   * @author ryo.aso
   */
  pushActiveId(id) {
    // 読み込み表示
    this.pushLoading();

    this.dispatch({
      actionType: `${EVENT_TYPE}:PUSH_ACTIVE_ID`,
      ...{ id },
    });

    // 読み込み非表示
    setTimeout(() => {
      this.dropLoading();
    }, 300);
  }

  /**
   * 選択節の解除
   * @version 2017/09/27
   * @author ryo.aso
   */
  dropActiveId() {
    this.dispatch({
      actionType: `${EVENT_TYPE}:DROP_ACTIVE_ID`,
    });
  }

  /**
   * 読み込み表示
   * @version 2017/09/27
   * @author ryo.aso
   */
  pushLoading() {
    this.dispatch({
      actionType: `${EVENT_TYPE}:PUSH_LOADING`,
    });
  }

  /**
   * 読み込み非表示
   * @version 2017/09/27
   * @author ryo.aso
   */
  dropLoading() {
    this.dispatch({
      actionType: `${EVENT_TYPE}:DROP_LOADING`,
    });
  }
}

export default new Action(dispatcher);
