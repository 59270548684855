// import modules
import { Component } from 'react';
import serialize from 'form-serialize'; // @see https://www.npmjs.com/package/form-serialize
// import action
import actions from './action.es6';

/**
 *  Container Class
 *  @version 2017/05/12
 *  @author ayana.kusu
 */
export default class Container extends Component {
  /**
   *  アップデート前に実行
   *  @version 2017/05/12
   *  @author ayana.kusu
   */
  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.valid) this.refs.form.submit();
  }

  /**
   *  コンストラクタ
   *  @version 2017/05/12
   *  @author ayana.kusu
   */
  validate(event) {
    // submit処理を抑制
    event.preventDefault();
    // フォームデータ取得
    actions.validate(serialize(this.refs.form, { hash: true, empty: true }));
  }

  /**
   *  表示処理
   *  @version 2017/05/12
   *  @author ayana.kusu
   */
  render() {
    const { authenticity_token, event, friend, lp, direct, valid, value, error } = this.props;

    return (
      <form
        action='/students/confirm'
        className='c-generalForm_form p-sessions_form'
        method='post'
        name='regist'
        ref='form'
      >
        <input type='hidden' name='authenticity_token' defaultValue={authenticity_token} />
        <input id='e' name='e' type='hidden' defaultValue={event} />
        <input id='f' name='f' type='hidden' defaultValue={friend} />
        <input id='lp' name='lp' type='hidden' defaultValue={lp} />
        <div className={`p-sessions_formField ${error.name ? 'p-sessions_formField-error' : null} `}>
          <label className='p-sessions_formLabel' htmlFor='name'>
            名前<small className='required'>※必須</small>
          </label>
          {error.name ? <p className='p-sessions_errorText'>{error.name}</p> : null}
          <input
            className='c-generalForm_text'
            name='student[name]'
            placeholder='名前を入力してください'
            type='text'
            defaultValue={value.name}
          />
        </div>
        <div className={`p-sessions_formField ${error.email ? 'p-sessions_formField-error' : null} `}>
          <label className='p-sessions_formLabel' htmlFor='email'>
            メールアドレス<small className='required'>※必須</small>
          </label>
          {error.email ? <p className='p-sessions_errorText'>{error.email}</p> : null}
          <input
            className='c-generalForm_text'
            name='student[email]'
            placeholder='メールアドレスを入力してください'
            readOnly={!direct}
            ref='email'
            type='text'
            defaultValue={value.email}
          />
        </div>
        <div className={`p-sessions_formField ${error.tel ? 'p-sessions_formField-error' : null} `}>
          <label className='p-sessions_formLabel' htmlFor='tel'>
            電話番号
          </label>
          {error.tel ? <p className='p-sessions_errorText'>{error.tel}</p> : null}
          <input
            className='c-generalForm_text'
            name='student[tel]'
            placeholder='ハイフン(-)は不要です'
            ref='tel'
            type='number'
          />
        </div>
        <div className={`p-sessions_formField ${error.password ? 'p-sessions_formField-error' : null} `}>
          <label className='p-sessions_formLabel' htmlFor='email'>
            パスワード<span className='required'>※必須</span>
          </label>
          {error.password ? <p className='p-sessions_errorText'>{error.password}</p> : null}
          <input
            className='c-generalForm_text'
            name='student[password]'
            placeholder='パスワードは英数字8文字以上で入力してください'
            type='password'
          />
        </div>
        <div className={`p-sessions_formField ${error.confirm ? 'p-sessions_formField-error' : null} `}>
          <label className='p-sessions_formLabel' htmlFor='email'>
            パスワード(確認)<span className='required'>※必須</span>
          </label>
          {error.confirm ? <p className='p-sessions_errorText'>{error.confirm}</p> : null}
          <input
            className='c-generalForm_text'
            name='student[password_confirmation]'
            placeholder='確認のため、もう一度入力してください'
            type='password'
          />
        </div>

        <div className='p-sessions_formField'>
          <input
            className='c-btnMain-primaryA c-btnSize-60 p-sessions_submit'
            type='submit'
            value='登録完了'
            disabled={valid}
            onClick={this.validate.bind(this)}
          />
        </div>
      </form>
    );
  }
}
