// import modules
import { combineReducers } from '@reduxjs/toolkit';
import { INITIALIZE, SET_PROFILE, OFF_MODAL } from './action.es6';

const teachers = (
  state = {
    modal: false,
  },
  action,
) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        ...action.props,
        ...state,
      };

    case SET_PROFILE:
      return {
        ...state,
        teacher: action.props,
        modal: true,
      };

    case OFF_MODAL:
      return {
        ...action.props,
        ...state,
        modal: false,
      };

    default:
      return state;
  }
};

const rootReducer = combineReducers({
  teachers,
});

export default rootReducer;
