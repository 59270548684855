// import modules
import fetch from 'common/fetch';
/**
 *  Method Name
 *  @const {String}
 */
export const INITIALIZE = 'INITIALIZE';
export const SET_PROFILE = 'SET_PROFILE';
export const OFF_MODAL = 'OFF_MODAL';

/**
 *  Action Method
 *  @const {object}
 */
export const initialize = (props) => ({
  type: INITIALIZE,
  props,
});

export const offModal = (props) => ({
  type: OFF_MODAL,
  props,
});
export const setProfile = (props) => ({
  type: SET_PROFILE,
  props,
});

export const fetchProfile = (id) => {
  return (dispatch) => {
    fetch(`/teacher_list/${id}.json`)
      .then((response) => response.json())
      .then((data) => {
        dispatch(setProfile(data));
      })
      .catch((error) => {
        console.error('Fetch error:', error);
      });
  };
};
