// import modules
import { Component } from 'react';

import Books from './components/books.jsx';
import Index from './components/index.jsx';
import Control from './components/control.jsx';

/**
 *  Container Class
 *  @version 2017/01/05
 *  @author ryo-as
 */
export default class Container extends Component {
  /**
   *  表示処理
   *  @version 2017/01/05
   *  @author ryo-as
   */
  render() {
    const { books, index, isLoading, activeId, isMobile } = this.props;

    return (
      <div className='p-browse'>
        {/* オーバーレイ */}
        <Loading {...{ isLoading }} />

        <Books {...{ books, activeId }} />

        <div className='p-browse_display'>
          <Index {...{ index, activeId }} />
          <div className='p-browse_text'>{isMobile ? <InformationMB /> : <InformationPC />}</div>

          {activeId && isMobile ? <Control /> : null}
        </div>
      </div>
    );
  }
}

/**
 *  読み込み中オーバーレイ
 *  @version 2016/05/02
 *  @author ryo.aso
 */
class Loading extends Component {
  /**
   *  表示処理
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  render() {
    if (!this.props.isLoading) return null;

    return (
      <div className='c-loading'>
        <div className='c-loading-wrap'>
          <div className='c-loading__content'>
            <i className='c-loading__icon'></i>
            <span className='c-loading__text'>Loading</span>
          </div>
        </div>
      </div>
    );
  }
}

/**
 *  インフォメーション
 *  @version 2016/05/02
 *  @author ryo.aso
 */
class InformationPC extends Component {
  /**
   *  表示処理
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  render() {
    return (
      <div className='p-browse_info'>
        <section className='p-browse_info__benefit'>
          <div>
            <h1>
              <img
                src='https://s3-ap-northeast-1.amazonaws.com/codecamp-cache/products/project/browse/info_headline_02.png'
                width='550'
                alt='３つの特典'
              />
            </h1>
            <ol className='p-browse_info__benefitList'>
              <li>
                <figure>
                  <img
                    src='https://s3-ap-northeast-1.amazonaws.com/codecamp-cache/products/project/browse/info_privilege1.png'
                    width='160'
                  />
                  <figcaption>無料でお試し学習</figcaption>
                  <p>
                    教科書の内容を
                    <br />
                    ご確認いただけます
                  </p>
                </figure>
              </li>
              <li>
                <figure>
                  <img
                    src='https://s3-ap-northeast-1.amazonaws.com/codecamp-cache/products/project/browse/info_privilege2.png'
                    width='160'
                  />
                  <figcaption>不明点は直接質問</figcaption>
                  <p>
                    無料カウンセリングを１回
                    <br />
                    ご受講いただけます
                  </p>
                </figure>
              </li>
              <li>
                <figure>
                  <img
                    src='https://s3-ap-northeast-1.amazonaws.com/codecamp-cache/products/project/browse/info_privilege3.png'
                    width='160'
                  />
                  <figcaption>割引クーポン取得</figcaption>
                  <p>
                    受講後アンケート回答で
                    <br />
                    クーポンプレゼント！
                  </p>
                </figure>
              </li>
            </ol>
          </div>
        </section>
        <nav className='p-browse_info__navi'>
          <p className='u-fs-12 u-mb-10'>簡単1分、準備不要！未経験でも丁寧に解説します</p>
          <a
            href='/schedules/trial'
            className='c-btnMain-primaryA c-btnSize-50 c-btn-radius c-btn-arrow'
            data-ccnt='CC体験予約遷移-browse1'
          >
            無料カウンセリングを試してみる
          </a>
        </nav>
        <section className='p-browse_info__recommend'>
          <div className='p-browse_info__recommendWrap'>
            <h1 className='p-browse_info__recommendTitle'>こんな方におすすめ</h1>
            <ul className='p-browse_info__recommendList'>
              <li>プログラミング学習を何から始めたらよいのかわからない方</li>
              <li>実際の学習内容を確認したい方</li>
              <li>CodeCampの学習システムを知りたい方</li>
            </ul>
          </div>
        </section>
      </div>
    );
  }
}

/**
 *  インフォメーション
 *  @version 2016/05/02
 *  @author ryo.aso
 */
class InformationMB extends Component {
  /**
   *  表示処理
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  render() {
    return (
      <div className='p-browse_info'>
        <section className='p-browse_info__benefit'>
          <div>
            <h1>
              <img
                src='https://s3-ap-northeast-1.amazonaws.com/codecamp-cache/products/project/browse/info_headline_02_sp.png'
                width='280'
                alt='３つの特典'
              />
            </h1>
            <ol className='p-browse_info__benefitList'>
              <li>
                <figure>
                  <img
                    src='https://s3-ap-northeast-1.amazonaws.com/codecamp-cache/products/project/browse/info_privilege1_sp.png'
                    width='112'
                  />
                  <div>
                    <figcaption>無料でお試し学習</figcaption>
                    <p>
                      教科書の内容を
                      <br />
                      ご確認いただけます
                    </p>
                  </div>
                </figure>
              </li>
              <li>
                <figure>
                  <img
                    src='https://s3-ap-northeast-1.amazonaws.com/codecamp-cache/products/project/browse/info_privilege2_sp.png'
                    width='112'
                  />
                  <div>
                    <figcaption>不明点は直接質問</figcaption>
                    <p>
                      無料カウンセリングを１回
                      <br />
                      ご受講いただけます
                    </p>
                  </div>
                </figure>
              </li>
              <li>
                <figure>
                  <img
                    src='https://s3-ap-northeast-1.amazonaws.com/codecamp-cache/products/project/browse/info_privilege3_sp.png'
                    width='112'
                  />
                  <div>
                    <figcaption>割引クーポン取得</figcaption>
                    <p>
                      受講後アンケート回答で
                      <br />
                      クーポンプレゼント！
                    </p>
                  </div>
                </figure>
              </li>
            </ol>
          </div>
        </section>
        <nav className='p-browse_info__navi'>
          <p className='u-fs-12 u-mb-10'>簡単1分、準備不要！未経験でも丁寧に解説します</p>
          <a
            href='/schedules/trial'
            className='c-btnMain-primaryA c-btnSize-50 c-btn-radius c-btn-arrow'
            data-ccnt='CC体験予約遷移-browse'
          >
            無料カウンセリングを試す
          </a>
        </nav>
        <section className='p-browse_info__recommend'>
          <div className='p-browse_info__recommendWrap'>
            <h1 className='p-browse_info__recommendTitle'>こんな方におすすめ</h1>
            <ul className='p-browse_info__recommendList'>
              <li>プログラミング学習を何から始めたらよいのかわからない方</li>
              <li>実際の学習内容を確認したい方</li>
              <li>CodeCampの学習システムを知りたい方</li>
            </ul>
          </div>
        </section>
      </div>
    );
  }
}
