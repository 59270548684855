/**
 * s3
 * @const {string}
 */
export const CACHE_RESOURCE = 'https://codecamp-cache.s3-ap-northeast-1.amazonaws.com/products/project/env_check/';

/**
 * SVGのタグ
 * @const {string}
 */
export const USE_TAG = '<use xlink:href="#svg-icn_targetBlank"></use>Z';
