/**
 *  Method Name
 *  @const {String}
 */
export const INITIALIZE = 'INITIALIZE';
export const PUSH_COURSE = 'PUSH_COURSE';
export const PUSH_PLAN = 'PUSH_PLAN';

/**
 *  Action Method
 *  @const {object}
 */
export const initialize = (props) => ({
  type: INITIALIZE,
  props,
});

export const pushCourse = (props) => ({
  type: PUSH_COURSE,
  props,
});

export const pushPlan = (props) => ({
  type: PUSH_PLAN,
  props,
});
