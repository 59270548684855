// extracted by mini-css-extract-plugin
var _1 = "admission__Jx_1S";
var _2 = "amount__CMRTg";
var _3 = "caption__h4yg7";
var _4 = "cart__VDlDT";
var _5 = "cart__button__jRUCy";
var _6 = "cart__course__KbV87";
var _7 = "cart__curriculums__qzV1v";
var _8 = "cart__discount__unKBH";
var _9 = "cart__heading__vbKs6";
var _a = "cart__info__P1_O_";
var _b = "cart__inner__QS38l";
var _c = "cart__item__CCQ8b";
var _d = "cart__note__P1Ij_";
var _e = "cart__plan__Crl0n";
var _f = "cart__text__cCIw0";
var _10 = "cart__total__OIGT2";
var _11 = "cart__wrap__OCYJc";
var _12 = "container__x9h0H";
var _13 = "coupon__ava4G";
var _14 = "courses__category__OzGqw";
var _15 = "courses__heading__p24U7";
var _16 = "courses__icon__adpzo";
var _17 = "courses__inner__ejgFN";
var _18 = "courses__item__Zn4Ex";
var _19 = "courses__label__OTZeW";
var _1a = "courses__list__nNM5v";
var _1b = "courses__title__VBYUQ";
var _1c = "fixed__button__hu5WH";
var _1d = "fixed__buttonWrap__SZmHS";
var _1e = "header__u7B0a";
var _1f = "header__title__MHJxU";
var _20 = "left__YNdgM";
var _21 = "plans__nwmCz";
var _22 = "plans__data__ho1u0";
var _23 = "plans__icon__iO1Ez";
var _24 = "plans__info__ShsbI";
var _25 = "plans__item__ZpwSd";
var _26 = "plans__label__tIE7E";
var _27 = "plans__name__zZcJ9";
var _28 = "pristine___J6gd";
export { _1 as "admission", _2 as "amount", _3 as "caption", _4 as "cart", _5 as "cart__button", _6 as "cart__course", _7 as "cart__curriculums", _8 as "cart__discount", _9 as "cart__heading", _a as "cart__info", _b as "cart__inner", _c as "cart__item", _d as "cart__note", _e as "cart__plan", _f as "cart__text", _10 as "cart__total", _11 as "cart__wrap", _12 as "container", _13 as "coupon", _14 as "courses__category", _15 as "courses__heading", _16 as "courses__icon", _17 as "courses__inner", _18 as "courses__item", _19 as "courses__label", _1a as "courses__list", _1b as "courses__title", _1c as "fixed__button", _1d as "fixed__buttonWrap", _1e as "header", _1f as "header__title", _20 as "left", _21 as "plans", _22 as "plans__data", _23 as "plans__icon", _24 as "plans__info", _25 as "plans__item", _26 as "plans__label", _27 as "plans__name", _28 as "pristine" }
