// import modules
import { reducer as formReducer } from 'redux-form';
import { INITIALIZE, PUSH_COURSE, PUSH_PLAN } from './action.es6';

const entries = (
  state = {
    phase: 'select',
    cart: null,
    course: null,
    plan: null,
  },
  action,
) => {
  switch (action.type) {
    case INITIALIZE:
      return { ...action.props, ...state };

    case PUSH_COURSE:
      const course = Object.values(state.courses).flatMap((x) => x.filter((y) => y.code === action.props))[0];
      return { ...state, course, plan: null, cart: null };

    case PUSH_PLAN:
      const { constants } = state;
      const plan = state.course.plans.find((x) => x.id === Number(action.props));
      return { ...state, plan, cart: decorateCart({ ...{ constants, plan } }) };

    default:
      return state;
  }
};

const rootReducer = {
  reducer: {
    entries,
    form: formReducer,
  },
};

/**
 * カート用のデータ成形
 * @param state - payments のreducerオブジェクト
 */
const decorateCart = ({ plan, constants }) => {
  const { admission_fee, current_admission_fee, comission_fee, consumption_tax, paying_member } = constants;
  // 諸経費 (入学金 or 事務手数料)
  const expense = paying_member ? comission_fee : current_admission_fee;
  // 合計額
  const total_amount = expense + plan.price;

  return {
    // 合計額
    total_amount,
    // 入学金の割引額
    admission_discount: admission_fee - current_admission_fee,
    // 合計額(税込)
    total_amount_tax: Math.floor(total_amount * (1 + consumption_tax)),
    // 税額
    tax: Number(Math.floor(total_amount * constants.consumption_tax)),
  };
};

export default rootReducer;
