// import modules
import { connect } from 'react-redux';
// import components and containers
import Courses from './courses';
import Steps from '../../../../../students/components/payments/components/steps';
import Cart from './cart';
// import style
import * as styleDesktop from '../style.module.sass';
import * as styleSp from '../style.sp.module.sass';

/**
 *  App
 *  @version 2019/11/14
 *  @author ayana.kusu
 */
const Template = ({ device, phase, style }) => (
  <div className={style.container}>
    <div className={style.left}>
      <div className={style.header}>
        <h1 className={style.header__title}>コース購入</h1>
        <Steps {...{ phase, device }} />
      </div>
      <Courses />
    </div>
    <Cart />
  </div>
);

/**
 *  connect redux
 *  @version 2019/11/14
 */
const mapStateToProps = ({ entries }) => ({
  ...entries,
  style: entries.device.match(/pc/) ? styleDesktop : styleSp,
});

export default connect(mapStateToProps)(Template);
