// import modules
import * as style from '../style.module.sass';
import { USE_TAG } from '../properties.es6';

/**
 *  ルート
 *  @version 2017/01/05
 *  @author ryo-as
 */
export default () => (
  <section className={style.section}>
    <h2 className={style.section__heading}>Googleアカウントのセットアップ</h2>
    <ul className={style.trouble}>
      <li className={style.trouble__item}>
        <h3 className={style.trouble__heading} data-accordion={false}>
          Googleアカウントの作成
        </h3>
        <div className={style.trouble__text}>
          <p>
            レッスンではGoogleアカウントへのログインが必要です。Googleアカウントをお持ちでない方は事前にアカウントの作成をお願いします。
          </p>
          <a
            href='https://accounts.google.com/SignUp?hl=ja'
            target='_blank'
            className={style.trouble__link}
            rel='noreferrer'
          >
            Googleアカウントを作成する
            <svg dangerouslySetInnerHTML={{ __html: USE_TAG }} className='u-linkBlank' />
          </a>
        </div>
      </li>
      <li className={style.trouble__item}>
        <h3 className={style.trouble__heading} data-accordion={false}>
          Google Meetの動作確認
        </h3>
        <div className={style.trouble__text}>
          <p>
            レッスンではGoogle Meetを使用します。Google
            Meetは事前に動作テストを実施して頂くと、よりスムーズにレッスンを受講頂けます。
          </p>
          <a
            href='https://codecamp.zendesk.com/hc/ja/articles/360052959291'
            target='_blank'
            className={style.trouble__link}
            rel='noreferrer'
          >
            Google Meet
            <svg dangerouslySetInnerHTML={{ __html: USE_TAG }} className='u-linkBlank' />
          </a>
        </div>
      </li>
    </ul>
  </section>
);
