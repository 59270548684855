import * as style from '../style.module.sass';

/**
 *  ブラウザ
 *  @version 2017/01/05
 *  @author ryo-as
 */
export default ({ isChrome, enabledCookie }) => (
  <section className={style.section}>
    <h2 className={style.section__heading}>ブラウザ</h2>
    <p className={style.section__text}>
      下記1〜3がすべて<span className={style.section__blue}>「OK」</span>になっているか確認してください。
      <br />
      <span className={style.section__red}>「NG」</span>になっている場合は、表示された手順に従ってください。
    </p>
    <ol className={style.browser}>
      <li className={style.browser__item}>
        <h3 className={style.browser__heading}>
          JavaScriptが有効になっているかを
          <br />
          チェックします
        </h3>
        <div className={style.browser__inner}>
          <dl>
            <dt className={style.browser__ok}>OK</dt>
            <dd>
              JavaScriptが
              <br />
              有効になっています
            </dd>
          </dl>
        </div>
      </li>
      <li className={style.browser__item}>
        <h3 className={style.browser__heading}>
          指定のブラウザを利用しているかを
          <br />
          チェックします
        </h3>
        <div className={style.browser__inner}>
          {isChrome ? (
            <dl>
              <dt className={style.browser__ok}>OK</dt>
              <dd>
                指定のブラウザ(Chrome)を
                <br />
                利用しています
              </dd>
            </dl>
          ) : (
            <dl>
              <dt className={style.browser__ng}>NG</dt>
              <dd>
                指定のブラウザ(Chrome)を
                <br />
                利用していません
              </dd>
            </dl>
          )}
        </div>
        {!isChrome && (
          <a href='#browser' className={style.browser__notice}>
            指定のブラウザを利用
          </a>
        )}
      </li>
      <li className={style.browser__item}>
        <h3 className={style.browser__heading}>
          Cookieが有効になっているかを
          <br />
          チェックします
        </h3>
        <div className={style.browser__inner}>
          {enabledCookie ? (
            <dl>
              <dt className={style.browser__ok}>OK</dt>
              <dd>
                Cookieが
                <br />
                有効になっています
              </dd>
            </dl>
          ) : (
            <dl>
              <dt className={style.browser__ng}>NG</dt>
              <dd>
                Cookieが
                <br />
                有効になっていません
              </dd>
            </dl>
          )}
        </div>
        {!enabledCookie && (
          <a href='#cookie' className={style.browser__notice}>
            Cookieを有効にする
          </a>
        )}
      </li>
    </ol>
  </section>
);
